// src/texts.js
export const texts = {
  sk: {
    header: {
      oNas: "O nás",
      sluzby: "Služby",
      referencie: "Referencie",
      kariera: "Kariéra",
      kontakt: "Kontakt",
      jazyk: "Jazyk",
      jazykSkratka: "SK",
      nocnyRezim: "Nočný režim",
    },
    hero: {
      title: "Váš spoľahlivý partner pre Elektroinštalácie",
      serviceBtn: "Služby",
      contactBtn: "Kontakt",
    },
    about: {
      imageAlt: "Elektrikkár pracujúci v H&S Buildings",
      title: "O Spoločnosti",
      paragraph1: "Spoločnosť",
      paragraph1span: " H&S Building ",
      paragraph1con:
        "je popredným poskytovateľom elektroinštalačných prác v Nemecku a Rakúsku.",
      paragraph2:
        "Vďaka skúsenostiam a profesionálnemu prístupu zaisťuje vysokú kvalitu a spoľahlivosť svojich služieb.",
      paragraph3:
        "Špecializujeme sa na komplexné elektroinštalačné riešenia pre komerčné aj rezidenčné projekty, s dôrazom na inovácie a bezpečnosť.",
      paragraph3span: " H&S Building ",
      paragraph3con:
        "sa neustále usiluje o zlepšovanie svojich technológií a postupov, aby splnila aj tie najnáročnejšie požiadavky svojich zákazníkov.",
      logoAlt: "Logo firmy",
      company: "H&S Building",
    },
    services: {
      title: "Ponúkané služby",
      service1Alt: "Bytové elektroinštalácie ikona",
      service1Desc: "Bytové elektroinštalácie",
      service2Alt: "Priemyselné elektroinštalácie",
      service2Desc: "Priemyselné elektroinštalácie",
      service3Alt: "Automatizácie",
      service3Desc: "Automatizácie",
      service4Alt: "Fotovoltika",
      service4Desc: "Fotovoltika",
    },
    references: {
      title: "Referencie",
      referenceImgAlt: "Realizácie firmy H&S Building",
      project1: {
        title: "Realizácia #1",
        description:
          "10 Bytových jednotiek s podzemnou garážou (Nemecko)",
      },
      project2: {
        title: "Realizácia #2",
        description: "Výmena osvetlenia (Nemecko)",
      },
      project3: {
        title: "Realizácia #3",
        description: "5 bytových jednotiek (Nemecko)",
      },
      project4: {
        title: "Realizácia #4",
        description: "Bytový komplex (Nemecko)",
      },
      project5: {
        title: "Realizácia #5",
        description: "10 Bytových jednotiek(Nemecko)",
      },
      project6: {
        title: "Realizácia #6",
        description: "Výmena fotovoltaických meničov (Nemecko)",
      },
      project7: {
        title: "Realizácia #7",
        description: "Výmena osvetlenia v parkovacom dome (Nemecko)",
      },
    },
    career: {
      title: "Kariéra",
      image1Alt: "Pracovník ukazujúci koncovky káblov",
      image2Alt: "Pracovník montuje v elektrickej skrine",
      description:
        "Máte záujem s nami spolupracovať? Môžete nás kontaktovať emailom",
      description1: "alebo prostredníctvom tohoto formulára.",
      form: {
        nameLabel: "Meno",
        namePlaceholder: "Jozef",
        surnameLabel: "Priezvisko",
        surnamePlaceholder: "Novák",
        mailLabel: "E-mail",
        mailPlaceholder: "jozefnovak@gmail.com",
        telLabel: "Tel. číslo",
        telPlaceholder: "0900 000 000",
        messageLabel: "Správa",
        messagePlaceholder: "Ako rýchlo môžem nastúpiť?",
        contactMethodLabel: "Kontaktujte ma pomocou:",
        contactMethodOptions: {
          phone: "Telefón",
          email: "Email",
          any: "Nezáleží",
        },
        privacyPolicyLabel: "Súhlasím so spracovaním",
        privacyPolicy: "osobných údajov",
        submitButton: "Odoslať",
        smallText:
          "Vaše osobné údaje budú použité na spracovanie vašej objednávky, podporu vášho zážitku na tejto webovej stránke a na iné účely opísané v našich zásadách ochrany osobných údajov.",
      },
    },
    footer: {
      logoAlt: "Logo firmy H&S Building",
      descriptionPrefix:
        "Sme popredným poskytovateľom elektroinštalačných prác v ",
      descriptionSpan1: "Nemecku",
      descriptionSeparator: " a ",
      descriptionSpan2: "Rakúsku",
      navigation: {
        about: "O nás",
        services: "Služby",
        references: "Referencie",
        career: "Kariéra",
        contact: "Kontakt",
      },
      companyAddress: {
        headline: "Adresa firmy",
        name: "H&S Building s.r.o.",
        street: "Lermontovova 911/3",
        city: "811 05 Bratislava - mestská časť Staré Mesto",
        icoLabel: "IČO:",
        ico: "54792011",
        dicLabel: "DIČ:",
        dic: "2121784192",
        icdphLabel: "IČ DPH: ",
        icdph: "SK2121784192",
      },
      operationalAddress: {
        headline: "Adresa prevádzky",
        name: "H&S Building s.r.o.",
        street: "Samuela Nováka 1572",
        city: "026 01 Dolný Kubín, Slovenská Republika",
        phoneLabel: "Telefón: ",
        phone: "+421 949 443 722",
        webLabel: "Web: ",
        web: "hsbuilding.eu",
        emailLabel: "E-mail: ",
        email: "info@hsbuilding.eu",
      },
      legalLinks: {
        terms: "OBCHODNÉ PODMIENKY",
        privacy: "OCHRANA OSOBNÝCH ÚDAJOV",
      },
      socialLinks: {
        facebook: "Facebook link",
        instagram: "Instagram link",
      },
      smallFooter:
        "© 2024 H&S Building | Umiestňovanie obsahu na iné stránky, jeho rozširovanie tlačou a inými médiami podlieha autorským právam a je možné iba s písomným súhlasom firmy H&S Building.",
    },
  },
  de: {
    header: {
      oNas: "Über uns",
      sluzby: "Dienstleistungen",
      referencie: "Referenzen",
      kariera: "Karriere",
      kontakt: "Kontakt",
      jazyk: "Sprache",
      jazykSkratka: "DE",
      nocnyRezim: "Nachtmodus",
    },
    hero: {
      title: "Ihr zuverlässiger Partner für Elektroinstallationen",
      serviceBtn: "Dienstleistungen",
      contactBtn: "Kontakt",
    },
    about: {
      imageAlt: "Elektriker, der in H&S Buildings arbeitet",
      title: "Über das Unternehmen",
      paragraph1: "Die Firma",
      paragraph1span: " H&S Building ",
      paragraph1con:
        "ist ein führender Anbieter von Elektroinstallationsarbeiten in Deutschland und Österreich.",
      paragraph2:
        "Dank Erfahrung und professionellem Ansatz gewährleistet sie hohe Qualität und Zuverlässigkeit ihrer Dienstleistungen.",
      paragraph3:
        "Wir spezialisieren uns auf umfassende Elektroinstallationslösungen für gewerbliche und private Projekte, mit Fokus auf Innovation und Sicherheit.",
      paragraph3span: " H&S Building ",
      paragraph3con:
        "strebt ständig danach, ihre Technologien und Verfahren zu verbessern, um die anspruchsvollsten Anforderungen ihrer Kunden zu erfüllen.",
      logoAlt: "Firmenlogo",
      company: "H&S Building",
    },
    services: {
      title: "Angebotene Dienstleistungen",
      service1Alt: "Wohnungselektroinstallationen Symbol",
      service1Desc: "Wohnung selektroinstallationen",
      service2Alt: "Industrielle Elektroinstallationen",
      service2Desc: "Industrielle Elektroinstallationen",
      service3Alt: "Automatisierungen",
      service3Desc: "Automatisierungen",
      service4Alt: "Photovoltaik",
      service4Desc: "Photovoltaik",
    },
    references: {
      title: "Referenzen",
      referenceImgAlt: "Realisierungen der Firma H&S Building",
      project1: {
        title: "Realisierung #1",
        description:
          "10 Wohneinheiten mit Tiefgarage (Deutschland)",
      },
      project2: {
        title: "Realisierung #2",
        description: "Lichtwechsel (Deutschland)",
      },
      project3: {
        title: "Realisierung #3",
        description: "5 Wohneinheiten (Deutschland)",
      },
      project4: {
        title: "Realisierung #4",
        description: "Wohnkomplex (Deutschland)",
      },
      project5: {
        title: "Realisierung #5",
        description: "10 Wohneinheiten (Deutschland)",
      },
      project6: {
        title: "Realisierung #6",
        description: "Austausch von Photovoltaik-Wechselrichtern (Deutschland)",
      },
      project7: {
        title: "Realisierung #7",
        description: "Lichtwechsel im Parkhaus (Deutschland)",
      },
    },

    career: {
      title: "Karriere",
      image1Alt: "Mitarbeiter zeigt Kabelenden",
      image2Alt: "Mitarbeiter montiert in elektrischem Schrank",
      description:
        "Interessieren Sie sich für eine Zusammenarbeit mit uns? Sie können uns per E-Mail",
      description1: "oder über dieses Formular kontaktieren.",
      form: {
        nameLabel: "Name",
        namePlaceholder: "Jozef",
        surnameLabel: "Nachname",
        surnamePlaceholder: "Novák",
        mailLabel: "E-Mail",
        mailPlaceholder: "jozefnovak@gmail.com",
        telLabel: "Telefonnummer",
        telPlaceholder: "0900 000 000",
        messageLabel: "Nachricht",
        messagePlaceholder: "Wie schnell kann ich anfangen?",
        contactMethodLabel: "Kontaktieren Sie mich über:",
        contactMethodOptions: {
          phone: "Telefon",
          email: "E-Mail",
          any: "Unabhängig",
        },
        privacyPolicyLabel: "Ich stimme der Verarbeitung",
        privacyPolicy: "personenbezogener Daten zu",
        submitButton: "Senden",
        smallText:
          "Ihre persönlichen Daten werden zur Bearbeitung Ihrer Bestellung, zur Unterstützung Ihres Erlebnisses auf dieser Website und für andere in unseren Datenschutzrichtlinien beschriebene Zwecke verwendet.",
      },
    },
    footer: {
        logoAlt: "Logo der Firma H&S Building",
        descriptionPrefix: "Wir sind ein führender Anbieter von Elektroinstallationsarbeiten in ",
        descriptionSpan1: "Deutschland",
        descriptionSeparator: " und ",
        descriptionSpan2: "Österreich",
        navigation: {
          about: "Über uns",
          services: "Dienstleistungen",
          references: "Referenzen",
          career: "Karriere",
          contact: "Kontakt",
        },
        companyAddress: {
          headline: "Firmenadresse",
          name: "H&S Building s.r.o.",
          street: "Lermontovova 911/3",
          city: "811 05 Bratislava - mestská časť Staré Mesto",
          icoLabel: "ID-Nr:",
          ico: "54792011",
          dicLabel: "Steuer-ID-Nr:",
          dic: "2121784192",
          icdphLabel: "USt-IdNr: ",
          icdph: "SK2121784192",
        },
        operationalAddress: {
          headline: "Betriebsadresse",
          name: "H&S Building s.r.o.",
          street: "Samuela Nováka 1572",
          city: "026 01 Dolný Kubín, Slowakische Republik",
          phoneLabel: "Telefon: ",
          phone: "+421 949 443 722",
          webLabel: "Web: ",
          web: "hsbuilding.eu",
          emailLabel: "E-Mail: ",
          email: "info@hsbuilding.eu",
        },
        legalLinks: {
          terms: "Geschäftsbedingungen",
          privacy: "Datenschutzbestimmungen",
        },
        socialLinks: {
          facebook: "Facebook-Link",
          instagram: "Instagram-Link",
        },
        smallFooter: "© 2024 H&S Building | Die Vervielfältigung von Inhalten auf anderen Websites, deren Druck oder Verwendung in anderen Medien unterliegt den Urheberrechten und ist nur mit schriftlicher Zustimmung der Firma H&S Building möglich.",
      },
      
  },
};
